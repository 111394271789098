
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import TourContiner from './containers/TourContiner';
import TourDetail from './containers/TourDetail';
import DashBoard from './containers/DashBoard';
import SignUp from './containers/SignUp';
import SignIn from './containers/SignIn';
import Profile from './containers/Profile';
import { useAuth } from "./hooks/useAuth";


const routerWithoutAuth = createBrowserRouter([
  {
    path: "/",
    element: <DashBoard />,

  },
  {
    path: "/singUp",
    element: <SignUp />,

  },
  {
    path: "/singin",
    element: <SignIn />,

  },
]);

const router = createBrowserRouter([
  {
    path: "/",
    element: <DashBoard />,

  },
  {
    path: "/singUp",
    element: <SignUp />,

  },
  {
    path: "/singin",
    element: <SignIn />,

  },
  {
    path: "/tourContiner",
    element: <TourContiner />,

  },
  {
    path: "/profile",
    element: <Profile />,

  },
  {
    path: "/tourDetail",
    element: <TourDetail />,
  },
]);

export default function Navigation() {
  const { user } = useAuth();
  return (
    <>


      <RouterProvider router={user ? router : routerWithoutAuth} />


    </>
  );
}


