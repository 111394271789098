import * as React from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, Modal, TextField } from '@mui/material';
import { getPofile,updateProfileRequest} from '../store/auth/actions';
import { AppState } from '../store/rootReducer';
import Nav from '../components/Nav';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import globalStyles from '../globalStyles';
import * as Yup from 'yup';



export default function Profile() {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const { loader,user } = useSelector((state: AppState) => state.auth)


  const validationSchema = Yup.object().shape({
    identityNumber: Yup.string()
      .required('identityNumber is required')
      .min(11, 'Username must be at least 11 characters'),
    name: Yup.string()
      .required('Username is required')
      .min(3, 'Username must be at least 3 characters'),
    surname: Yup.string()
      .required('Surname is required')
      .min(3, 'surname must be at least 3 characters'),
    address: Yup.string()
      .required('Adress is required')
      .min(10, 'address must be at least 10 characters'),
    iban: Yup.string()
      .required('Iban is required')
      .min(26, 'iban must be at least 26 characters'),
    about: Yup.string()
      .nullable()
      .notRequired()
      .when('githubUsername', {
          is: (value) => value?.length,
          then: (rule) => rule.min(30),
      }),
    instagram: Yup.string().nullable()
    .notRequired(),
    facebook: Yup.string().nullable()
      .notRequired(),
    tiktok: Yup.string().nullable()
      .notRequired(),
    linkedin: Yup.string().nullable()
      .notRequired(),
    twitter: Yup.string().nullable()
      .notRequired()
  
  });

  const { control, handleSubmit, reset, formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      surname:user?.surname,
      name: user?.name,
      identityNumber: user?.identityNumber,
      address: user?.address,
      iban: user?.iban,
      about: user?.about,
      instagram: user?.socialMedia?.instagram,
      facebook: user?.socialMedia?.facebook,
      tiktok: user?.socialMedia?.tiktok,
      linkedin: user?.socialMedia?.linkedin,
      twitter: user?.socialMedia?.twitter,
    },
    resolver: yupResolver(validationSchema)
  });


  const onSubmit = data => {
    if (isValid) {
      const { instagram, facebook, tiktok, linkedin, twitter, ...rest } = data;
      const socialMedia = { instagram, facebook, tiktok, linkedin, twitter }
      
      dispatch(updateProfileRequest({
        ...rest, socialMedia, email: user?.email,
        onSuccess: () => {
          setOpen(false)
        },
        onFailure: () =>   setOpen(false),
      }));
    }
  }

  const socialMedia = (data) => {
    let socialMedia = "";
    Object.entries(data).forEach(([key, val]) => socialMedia += val + ", ")
    return socialMedia;
  }


  React.useEffect(() => {
    if (!user) {
      dispatch(getPofile());
    } else {
      reset({
        surname: user?.surname ?? '',
        name: user?.name ?? '',
        identityNumber: user?.identityNumber ?? '',
        address: user?.address ?? '',
        iban: user?.iban ?? '',
        about: user?.about ?? '',
        instagram: user?.socialMedia?.instagram ?? '',
        facebook: user?.socialMedia?.facebook ?? '',
        tiktok: user?.socialMedia?.tiktok ?? '',
        linkedin: user?.socialMedia?.linkedin ?? '',
        twitter: user?.socialMedia?.twitter ?? '',
    });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.name, dispatch]);


  return (
    <div>
      <Nav />
      {!user?.identityNumber &&
        <div className="d-flex justify-content-end px-2 pt-3">
          <div >
            <Button onClick={()=>{setOpen(true)}} >
              Complete Profile
            </Button>
          </div>
        </div>
      }
    
      {
        loader
          ?
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress></CircularProgress>
          </Box>
          :
          <div className="d-flex flex-column p-3" >

            <p><span className='h6'>{t("guide.identityNumber")}: </span>{user?.identityNumber}</p>
            <p><span className='font-bold h6'>{t("global.name")}: </span>{user?.name}</p>
            <p><span className='h6'>{t("global.last_name")}: </span>{user?.surname}</p>
            <p><span className='h6'>{t("global.email")}: </span>{user?.email}</p>
            <p><span className='h6'>{t("guide.about")}: </span>{user?.about}</p>
            <p><span className='h6'>{t("guide.address")}: </span>{user?.address}</p>
            <p><span className='h6'>{t("guide.iban")}: </span>{user?.iban}</p>
            <p><span className='h6'>{t("guide.subMerchantType")}: </span>{user?.subMerchantType}</p>
            <p><span className='h6'>{t("guide.socialMedia")}: </span>{user?.socialMedia ? socialMedia(user?.socialMedia) : ""}</p>
      
            <p><span className='h6'>{t("guide.type")}: </span>{user?.type}</p>
           
          </div>
      }


<Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={globalStyles.container} >
          <h2 className="text-center">Update Profile</h2>
          <p className="text-center text-danger">You can not change profile later. To be able to get payment, please be careful while entering your info</p>
          <form id="update-profile-form" onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column overflow-auto pb-3">
          <Controller
              name="identityNumber"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["identityNumber"]}
                helperText={errors['identityNumber'] ? errors['identityNumber'].message : ''}
                className="mt-2"
                label="identityNumber"
    
              />}
            />

            <Controller
              name="name"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["name"]}
                helperText={errors['name'] ? errors['name'].message : ''}
                className="mt-2"
                label="name"
                
 
              />}
            />
            <Controller
              name="surname"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["surname"]}
                helperText={errors['surname'] ? errors['surname'].message : ''}
                className="mt-2"
                label="surname"
             
              />}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["address"]}
                helperText={errors['address'] ? errors['address'].message : ''}
                className="mt-2"
                label="address"
                multiline
                maxRows={3}
              />}
            />
            <Controller
              name="iban"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["iban"]}
                helperText={errors['iban'] ? errors['iban'].message : ''}
                className="mt-2"
                label="iban"
        
              />} 
            />
            <Controller
              name="about"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["about"]}
                helperText={errors['about'] ? errors['about'].message : ''}
                className="mt-2"
                label="about"
                maxRows={6}
              />}
            />
            <Controller
              name="instagram"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["instagram"]}
                helperText={errors['instagram'] ? errors['instagram'].message : ''}
                className="mt-2"
                label="instagram"
                maxRows={6}
              />}
            />
             <Controller
              name="facebook"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["facebook"]}
                helperText={errors['facebook'] ? errors['facebook'].message : ''}
                className="mt-2"
                label="facebook"
                maxRows={6}
              />}
            />
              <Controller
                name="tiktok"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  error={!!errors["tiktok"]}
                  helperText={errors['tiktok'] ? errors['tiktok'].message : ''}
                  className="mt-2"
                  label="tiktok"
                  maxRows={6}
                />}
            />
              <Controller
                name="linkedin"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  error={!!errors["linkedin"]}
                  helperText={errors['linkedin'] ? errors['linkedin'].message : ''}
                  className="mt-2"
                  label="linkedin"
                  maxRows={6}
                />}
            />
              <Controller
                name="twitter"
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  error={!!errors["twitter"]}
                  helperText={errors['twitter'] ? errors['twitter'].message : ''}
                  className="mt-2"
                  label="twitter"
                  maxRows={6}
                />}
            />

          </form>
          <div className="d-flex flex-row-reverse">
            <Button form="update-profile-form" type="submit" disabled={loader}>
              Save Changes
            </Button>
          </div>

          {
            loader
            &&
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              <CircularProgress></CircularProgress>
            </Box>
          }


        </Box>

      </Modal>

    </div>

  );
}

