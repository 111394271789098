import axios from "axios";


const UNAUTHORIZED_APIS: string[] = [
    "users/refresh",
    "users/login"
];

class HttpService {

    isRefreshingToken = false;

    init() {
        // axios.defaults.baseURL = "http://localhost:8080/";
        // axios.defaults.baseURL = "https://api-dev.echopath.app/";
        axios.defaults.baseURL = "https://api.echopath.app/";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Accept"] = "application/json";

        // interceptor: adding token for requests
        axios.interceptors.request.use(
            config => {
                // const token = storageService.getString(STORAGE_KEYS.AUTH_TOKEN);
                const token = window.localStorage.getItem("token")

                if (token) {
                    //add non-auth api list to here
                    if (config.url) {
                        //NOTE(ibrahim): If we add a query parameter added endpoint
                        // to the non-auth endpoints list the UNAUTHORIZED_APIS.indexOf was not
                        // working correctly because the full url has "?key=value" part in it
                        // so we need to extract query parameter to be able to correctly
                        // determine if the endpoint is a non-auth or normal one
                        const queryParamStartIndex = config.url.indexOf("?");
                        const processedUrl = queryParamStartIndex === -1 ? config.url : config.url.substring(0, queryParamStartIndex);

                        if (UNAUTHORIZED_APIS.indexOf(processedUrl) > -1) {
                            if (config.headers) {
                                delete config.headers["Authorization"];
                            }
                        } else {
                            if (config.headers) {
                                config.headers["Authorization"] = "Bearer "+token;
                            }
                        }
                    }
                }
                return config;
            },
            error => {
                Promise.reject(error);
            });

        axios.interceptors.response.use(response => {
            return response;
        }, async error => {
            const originalRequest = error.config;

            if (originalRequest.url === "users/refresh") {
                // eventBusService.sendMessageToBus(EVENT_TYPES.LOGOUT_SUCCESS, undefined);
                return Promise.reject(error);
            } else if (error.response.status === 403 && !originalRequest._retry) {
                if (this.isRefreshingToken) {
                    setTimeout(() => {
                        axios(originalRequest);
                    }, 1000);

                    return;
                }
                this.isRefreshingToken = true;
                originalRequest._retry = true;
                // const refreshToken = storageService.getString(STORAGE_KEYS.REFRESH_TOKEN);
                // const refreshToken = "storageService.getString(STORAGE_KEYS.REFRESH_TOKEN);"
                // if (refreshToken) {
                //     const uuid = storageService.getString(STORAGE_KEYS.UUID);
                //     const newAuthInfo = await axios.post("users/refresh", {
                //         uuid, token: refreshToken
                //     });

                //     if (newAuthInfo) {
                //         storageService.set(STORAGE_KEYS.AUTH_TOKEN, newAuthInfo.data.token);
                //         storageService.set(STORAGE_KEYS.REFRESH_TOKEN, newAuthInfo.data.refreshToken);
                //         this.isRefreshingToken = false;
                //         return axios(originalRequest);
                //     } else {
                //         return Promise.reject(error);
                //     }
                // }
            }
            this.isRefreshingToken = false;
            return Promise.reject(error);
        });
    }

    addUnAuthorizedEndpoints(customUnauthorizedApis: string[]) {
        customUnauthorizedApis.forEach(endpoint => UNAUTHORIZED_APIS.push(endpoint))
    }

    get<T = any>(url: string, queryParams?: Record<string, string>) {
        if (queryParams) {
            const query = new URLSearchParams(queryParams).toString()
            if (query.length > 0) {
                if (url.includes('?')) {
                    url += '&';
                } else {
                    url += '?';
                }

                url += query;
            }
        }

        return axios.get<T>(url);
    }

    post<T = any>(url: string, params: any) {
        return axios.post<T>(url, params);
    }

    update(url: string, params: any) {
        return axios.put(url, params);
    }

    put(url: string, params: any) {
        return axios.put(url, params);
    }

    delete(url: string, params?: any) {
        if (params) {
            return axios.delete(url, { data: params });
        }
        return axios.delete(url);
    }
}

export const httpService = new HttpService();
