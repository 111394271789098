import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardContent, CardHeader, CardMedia, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import { getTourRequest, postTourRequest } from '../store/tour/actions';
import { AppState } from '../store/rootReducer';
import Nav from '../components/Nav';
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone'
import Delete from '@mui/icons-material/Delete';
import globalStyles from '../globalStyles';
import { getPofile } from '../store/auth/actions';



export default function TourContiner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [profileModal, setProfileModal] = React.useState(false);
  const [file, setFile] = React.useState<File>(null);
  const [fileError, setFileError] = React.useState(false);

  const tourList = useSelector((state: AppState) => state.tour.tourList)
  const { loader } = useSelector((state: AppState) => state.tour)


  const { user } = useSelector((state: AppState) => state.auth)


  React.useEffect(() => {
    dispatch(getTourRequest());
    if (!user?.name) {
      dispatch(getPofile());
    }
  }, [dispatch,user?.name]);




  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Username is required')
      .min(3, 'Username must be at least 3 characters'),
    description: Yup.string()
      .required('Description is required')
      .min(30, 'Description must be at least 30 characters'),
    country: Yup.string()
      .required('Country is required')
      .min(3, 'Country must be at least 3 characters'),
    city: Yup.string()
      .required('City is required')
      .min(3, 'City must be at least 3 characters'),
    price: Yup.number()
      .typeError('Amount must be a number')
      .required("Please provide price."),
    currency: Yup.string()
      .required('Currency is required')
  });

  const { control, handleSubmit, reset, formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      description: "",
      name: "",
      city: "",
      country: "",
      price: "",
      currency: "",
      hasMap: false
    },
    resolver: yupResolver(validationSchema)
  });


  const onSubmit = data => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      Object.entries(data).forEach(([key, val]) => {
        formData.append(key, val as string);
      });

      const meta = {
        onSuccess: () => {
          setOpen(false);
          setFile(null)
          reset()
        }
      }
      if (isValid) {
        dispatch(postTourRequest(formData, meta));
      }
    } else {
      setFileError(true)
    }
  }


  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 3000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0])
      setFileError(false)
    },
  });

  const files = file ? [file].map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton onClick={() => setFile(null)} color="error" aria-label="upload picture" component="label">
        <Delete />
      </IconButton>
    </li>
  )) : null


  const addNewTour = () => {
    if (!user?.identityNumber) {
      setProfileModal(true)
    } else {
      setOpen(true)
    }
  }


  return (
    <div>
      <Nav />
      <div className="d-flex justify-content-end px-2 pt-3">
        <div >
          <Button onClick={addNewTour} >
            {t("tour.add_new_tour")}
          </Button>
        </div>
      </div>

      {
        loader
          ?
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress></CircularProgress>
          </Box>
          :
          <div style={{ display: "flex", flex: 1, flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>

            {Boolean(tourList) && tourList.tours && tourList.tours.length > 0 &&
              tourList.tours.map((item) => {
                return (
                  <Card variant="outlined" sx={{ m: 1, width: 1 / 5 }} key={item._id}>
                    <Box sx={{ my: 4, mx: 1, cursor: 'pointer' }} onClick={() => {
                      navigate("/tourDetail?id=" + item._id)
                    }}>
                      <CardHeader

                        action={
                          <IconButton aria-label="settings">
                          </IconButton>
                        }
                        title={item.name}
                        subheader={moment(item.updatedAt).format("YYYY-MM-DD")}
                      />
                      <CardMedia
                        component="img"
                        height="194"
                        src={"https://api.echopath.app/" + item.imageUrl}
                        image={!item.imageUrl && "https://picsum.photos/id/237/500/300"}
                        alt="Paella dish"
                      />
                      <CardContent>

                        <Typography variant="body2">
                          {item.description}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                )
              })
            }
          </div>
      }

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={globalStyles.container} >
          <h2 className="text-center">Add New Tour</h2>

          <form id="create-tour-form" onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column overflow-auto pb-3">
            <Controller
              name="name"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["name"]}
                helperText={errors['name'] ? errors['name'].message : ''}
                className="mt-2"
                label="name"
                multiline
                maxRows={4}
              />}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["description"]}
                helperText={errors['description'] ? errors['description'].message : ''}
                className="mt-2"
                label="description"
                multiline
                maxRows={6}
              />}
            />

            <Controller
              name="country"
              control={control}
              render={({ field }) => <TextField    {...field} error={!!errors["country"]}
                helperText={errors['country'] ? errors['country'].message : ''} className="mt-2" label="country" variant="outlined" />}
            />

            <Controller
              name="city"
              control={control}
              render={({ field }) => <TextField    {...field} error={!!errors["city"]}
                helperText={errors['city'] ? errors['city'].message : ''} className="mt-2" label="city" variant="outlined" />}
            />

            <Controller
              name="price"
              control={control}
              render={({ field }) => <TextField    {...field} error={!!errors["price"]}
                type='number'
                helperText={errors['price'] ? errors['price'].message : ''} className="mt-2" label="price" variant="outlined" />}
            />


            <Controller
              name="currency"
              control={control}
              render={({ field }) =>
                <FormControl className="mt-2" >
                  <InputLabel id="demo-simple-select-label">currency</InputLabel>
                  <Select

                    {...field}
                    error={!!errors["currency"]}

                    labelId="demo-simple-select-label"
                    label="currency"

                  >
                    <MenuItem value={"TL"}>TL</MenuItem>
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"EURO"}>EURO</MenuItem>
                  </Select>
                  <FormHelperText>{errors['currency'] ? errors['currency'].message : ''}</FormHelperText>
                </FormControl>}
            />


            <Controller
              name="hasMap"
              control={control}
              render={({ field }) =>
                <FormControlLabel
                  className="mt-2"

                  control={
                    <Checkbox  {...field} />
                  }
                  label="Has Map"
                  labelPlacement="end"
                />}
            />

            <div {...getRootProps({ className: 'dropzone' })} >
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center align-items-center rounded-3 text-center" role="button" style={fileError ? globalStyles.dragDropError : globalStyles.dragDrop}>
                Drag 'n' drop image file here, or click to select file
                <br />
                max 3 mb
              </div>
            </div>
            <aside className="mt-2">
              {
                fileError ?
                  <div className="text-danger"> Must add image</div>
                  :
                  <ul>{files}</ul>
              }

            </aside>


          </form>
          <div className="d-flex flex-row-reverse">
            <Button form="create-tour-form" type="submit" disabled={loader}>
              Save Changes
            </Button>
          </div>

          {
            loader
            &&
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              <CircularProgress></CircularProgress>
            </Box>
          }


        </Box>

      </Modal>




      <Modal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={globalStyles.container} >
          <h2 className="text-center text-danger">To add tour please complete your profile</h2>
          <p className="text-center ">All information you provide can not changed in the future, you must give all info correct to be able to get your payment</p>
          <div >
            <Button onClick={() => {
              setProfileModal(false);
              navigate("/profile")
            }} >
              Complete Profile
            </Button>
          </div>
        </Box>
      </Modal>
    </div>

  );
}

