import {
    TRY_LOGIN_REQUEST,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    SET_LOADER_STATE,
    TRY_SINGUP_REQUEST,
    SET_PROFILE,
    GET_PROFILE_REQUEST,
    UPDATE_PROFILE_REQUEST
} from "./actionTypes";
import * as $AT from "./types";

export const tryLoginRequest = (   payload: $AT.Ilogin): $AT.TryLogin => ({
    type: TRY_LOGIN_REQUEST,
    payload
});

export const loginSuccess = (
    payload: $AT.ILoginSuccessPayload
): $AT.LoginSuccess => ({
    type: LOGIN_SUCCESS,
});

export const loginFailure = (
    payload: $AT.ILoginFailurePayload
): $AT.LoginFailure => ({
    type: LOGIN_FAILURE,
});

export const setLoaderState = (   payload: boolean): $AT.ISetLoader => ({
    type: SET_LOADER_STATE,
    payload
});

export const trySingUpRequest = (   payload: $AT.ISingUp): $AT.TrySingUp => ({
    type: TRY_SINGUP_REQUEST,
    payload
});

export const getPofile= (): $AT.IGetProfile => ({
    type:GET_PROFILE_REQUEST,
});

export const setPofile= (payload:$AT.IProfile ): $AT.ProfileSuccess => ({
    type:SET_PROFILE,
    payload
});


export const updateProfileRequest = (   payload: $AT.IProfile): $AT.IUpdateProfile => ({
    type: UPDATE_PROFILE_REQUEST,
    payload
});