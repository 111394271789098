const globalStyles = {
    container: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        maxHeight: "80%",
        transform: 'translate(-50%, -50%)',
        width: "50%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column"
    },
    dragDrop: { border: "2px dotted #dee2e6", minHeight: "100px" },
    dragDropError: { border: "2px dotted #dc3545", minHeight: "100px" }

}

export default globalStyles