import { httpService } from "../../services/httpService";
import { IOrderTourItems, ITour, ITourItem } from "./types";
class TourFactory {

    getTours() {
        return httpService.get("tour/list")
    }

    setTour(data: FormData) {
        return httpService.post("tour/post", data)
    }

    deleteTour(id: string) {
        return httpService.delete(`tour/delete?id=${id}`);
    }

    getTourDetail(id: string) {
        return httpService.get(`tour?id=${id}`)
    }

    updateTour(data: ITour | FormData) {
        return httpService.post(`tour/update`, data)
    }

    //tour Items
    setTourItem(data: FormData) {
        return httpService.post("tourItem/post", data)
    }

    getTourItems(tourItemId:string) {
        return httpService.get(`tourItem/list?tourItemId=${tourItemId}`)
    }

    deleteTourItem(tourItemId: string) {
        return httpService.delete(`tourItem/delete?tourItemId=${tourItemId}`);
    }

    updateTourItem(data: ITourItem | FormData) {
        return httpService.post("tourItem/update", data)
    }

    updateTourItemOrder(data: IOrderTourItems[]) {
        return httpService.post("tourItem/updateorder", { array: data })
    }

}

export const tourFactory = new TourFactory();
