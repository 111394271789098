import { combineReducers } from "redux";

import authReducer from "./auth/reducer";
import tourReducer from "./tour/reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    tour:tourReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;