import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { trySingUpRequest } from '../store/auth/actions';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../store/rootReducer';
import CircularProgress from '@mui/material/CircularProgress';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../helper/validations';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignUp() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { login } = useAuth();

    const loader = useSelector((state: AppState) => state.auth.loader);
    const [nameInput, setNameInput] = React.useState('');
    const [surnameInput, setSurNameInput] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [pass, setPass] = React.useState('');

    const handleNameInputChange = event => {
        setNameInput(event.target.value);
    };

    const handleSurNameInputChange = event => {
        setSurNameInput(event.target.value);
    };

    const handleEmailInputChange = event => {
        setEmail(event.target.value);
    };
    const handlePassInputChange = event => {
        setPass(event.target.value);
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataObj = {
            email: data.get('email').toString(),
            password: data.get('password').toString(),
            name: data.get('name').toString(),
            surname: data.get('surname').toString(),
            onSuccess: () => {
                setTimeout(() => {
                    login({
                        email: data.get('email'),
                        password: data.get('password'),
                    })
                    navigate("/TourContiner")
                }, 1000);
            },
            onFailure: () => console.log("onFailure singup"),
        }
        dispatch(trySingUpRequest(dataObj));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={handleNameInputChange}
                                    error={nameInput.length < 3 && nameInput.length > 0}
                                    autoComplete="given-name"
                                    name="name"
                                    required
                                    fullWidth
                                    id="name"
                                    label={t("sign_in.first_name")}
                                    autoFocus
                                    helperText={nameInput.length < 3 && nameInput.length > 0 && t("errors.at_least", { number: 3 })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={handleSurNameInputChange}
                                    error={surnameInput.length < 3 && surnameInput.length > 0}
                                    helperText={surnameInput.length < 3 && surnameInput.length > 0 && t("errors.at_least", { number: 3 })}
                                    required
                                    fullWidth
                                    id="surname"
                                    label={t("sign_in.last_name")}
                                    name="surname"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={handleEmailInputChange}
                                    error={!EMAIL_PATTERN.test(email) && email.length > 0}
                                    helperText={!EMAIL_PATTERN.test(email) && email.length > 0 && t("sign_in.email_error")}
                                    required
                                    fullWidth
                                    id="email"
                                    label={t("sign_in.email")}
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={handlePassInputChange}
                                    error={!PASSWORD_PATTERN.test(pass) && pass.length > 0}
                                    required
                                    fullWidth
                                    name="password"
                                    label={t("sign_in.pass")}
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                                {
                                    !PASSWORD_PATTERN.test(pass) && pass.length > 0 &&
                                    <>
                                        <div>
                                            <small className='text-danger'>
                                                * {t("errors.one_upper_case")}
                                            </small>
                                        </div>
                                        <div>
                                            <small className='text-danger'>
                                                * {t("errors.one_lower_case")}
                                            </small>
                                        </div>
                                        <div>
                                            <small className='text-danger'>
                                                * {t("errors.one_digit")}
                                            </small>
                                        </div>
                                        <div>
                                            <small className='text-danger'>
                                                * {t("errors.at_least", { number: 8 })}
                                            </small>
                                        </div>
                                    </>
                                }


                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={ !PASSWORD_PATTERN.test(pass) || !EMAIL_PATTERN.test(email) || nameInput.length < 3 || surnameInput.length < 3}
                        >
                            {t("sign_in.sign_up")}
                        </Button>
                        {
                            loader
                            &&
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                <CircularProgress></CircularProgress>
                            </Box>
                        }
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/singin" variant="body2">
                                    {t("sign_in.already_have_account")}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}