import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import Publish from "@mui/icons-material/Publish";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";
import { useNavigate } from "react-router-dom";
import { deleteTourRequest, updateTourRequest } from "../../store/tour/actions";
import { useTranslation } from "react-i18next";
import globalStyles from "../../globalStyles";
import { useDropzone } from "react-dropzone";
import Delete from "@mui/icons-material/Delete";



export default function TourDetailHeader({ tourId }: { tourId: string }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  // const [loader, setLoader] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [imageFile, setImageFile] = useState<File>(null);
  const { tour } = useSelector((state: AppState) => state.tour)


  const deleteAction = () => {
    const meta = {
      onSuccess: () => {
        setOpenDelete(false);
        navigate("/TourContiner")
      }
    }
    dispatch(deleteTourRequest(tourId, meta));
  }


  const validationSchema = Yup.object().shape({

    name: Yup.string()
      .required('Username is required')
      .min(3, 'Username must be at least 3 characters'),
    description: Yup.string()
      .required('Description is required')
      .min(30, 'Description must be at least 30 characters'),
    country: Yup.string()
      .required('Country is required')
      .min(3, 'Country must be at least 3 characters'),
    city: Yup.string()
      .required('City is required')
      .min(3, 'City must be at least 3 characters'),
    price: Yup.number()
      .typeError('Amount must be a number')
      .required("Please provide price."),
    currency: Yup.string()
      .required('Currency is required')

  });





  const { control, handleSubmit, reset, formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      description: "",
      name: "",
      city: "",
      country: "",
      price: "",
      currency: "",
      hasMap: false
    },

    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (tour) {
      const newDefaults = {
        description: tour.description ?? "",
        name: tour.name ?? "",
        city: tour.city ?? "",
        country: tour.country ?? "",
        price: tour.price ?? "",
        currency: tour.currency ?? "",
        hasMap: tour.hasMap ?? false
      }
      reset(newDefaults)
    }

  }, [tour, reset])


  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
    maxSize: 3000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    onDrop: (acceptedFiles) => {
      setImageFile(acceptedFiles[0])
    },
  });

  const imageFileList = imageFile ? [imageFile].map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton onClick={() => setImageFile(null)} color="error" aria-label="upload picture" component="label">
        <Delete />
      </IconButton>
    </li>
  )) : null




  const onSubmit = data => {
    const meta = {
      onSuccess: () => {
        setOpen(false);
        setImageFile(null)
        reset()
      }
    }
    if (!isValid) {
      return
    }
    if (imageFile) {
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append("_id", tourId);
      Object.entries(data).forEach(([key, val]) => {
        formData.append(key, val as string);
      });
      dispatch(updateTourRequest(formData, meta));
    }
    if (isValid) {
      dispatch(updateTourRequest({ ...data, _id: tourId, imageUrl: tour.imageUrl }, meta));
    }
  }

  return (
    <div>


      <div className="d-flex justify-content-between pt-3">
        <div className="col">
          <Link href="/TourContiner">
            <Button startIcon={<ArrowBackIcon />} >
            </Button>
          </Link>
        </div>

        <div className="d-flex justify-content-between">


          <div >
            <Button startIcon={<DeleteIcon />} onClick={() => setOpenDelete(true)} >
              {t("global.delete")}
            </Button>
          </div>

          <div >
            <Button startIcon={<EditIcon />} onClick={() => setOpen(true)} >
              {t("global.edit")}
            </Button>
          </div>

          <div >
            <Button startIcon={<Publish />}  >
              {t("global.publish")}
            </Button>
          </div>

        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={globalStyles.container} >
          <h2 className="text-center">{t("global.edit_content")}</h2>

          <form id="tour-detail-form" onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column overflow-auto pb-3">
            <Controller
              name="name"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["name"]}
                helperText={errors['name'] ? errors['name'].message : ''}
                className="mt-2"
                label={t("global.name")}
                multiline
                maxRows={4}
              />}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["description"]}
                helperText={errors['description'] ? errors['description'].message : ''}
                className="mt-2"
                label={t("global.description")}
                multiline
                maxRows={6}
              />}
            />

            <Controller
              name="country"
              control={control}
              render={({ field }) => <TextField    {...field} error={!!errors["country"]}
                helperText={errors['country'] ? errors['country'].message : ''} className="mt-2" label={t("tour.country")} variant="outlined" />}
            />

            <Controller
              name="city"
              control={control}
              render={({ field }) => <TextField    {...field} error={!!errors["city"]}
                helperText={errors['city'] ? errors['city'].message : ''} className="mt-2" label={t("tour.city")} variant="outlined" />}
            />

            <Controller
              name="price"
              control={control}
              render={({ field }) => <TextField    {...field} error={!!errors["price"]}
                type='number'
                helperText={errors['price'] ? errors['price'].message : ''} className="mt-2" label={t("tour.price")} variant="outlined" />}
            />


            <Controller
              name="currency"
              control={control}
              render={({ field }) =>
                <FormControl className="mt-2" >
                  <InputLabel id="demo-simple-select-label">{t("tour.currency")}</InputLabel>
                  <Select

                    {...field}
                    error={!!errors["currency"]}

                    labelId="demo-simple-select-label"
                    label={t("tour.currency")}

                  >
                    <MenuItem value={"TL"}>TL</MenuItem>
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"EURO"}>EURO</MenuItem>
                  </Select>
                  <FormHelperText>{errors['currency'] ? errors['currency'].message : ''}</FormHelperText>
                </FormControl>}
            />


            <Controller
              name="hasMap"
              control={control}
              render={({ field }) =>
                <FormControlLabel
                  className="mt-2"

                  control={
                    <Checkbox  {...field} />
                  }
                  label={t("tour.has_map")}
                  labelPlacement="end"
                />}
            />


            <div {...getRootPropsImage({ className: 'dropzone mt-2' })} >
              <input {...getInputPropsImage()} />
              <div className="d-flex justify-content-center align-items-center text-center rounded-3" role="button" style={globalStyles.dragDrop}>
                Drag 'n' drop image file here, or click to select file
                <br />
                max 3 mb
              </div>
            </div>
            <aside className="mt-2">
              {
                imageFileList ?
                  <ul>{imageFileList}</ul>
                  :
                  <CardMedia
                    component="img"
                    height="194"
                    src={"https://api.echopath.app/" + tour.imageUrl}
                    image={!tour.imageUrl && "https://picsum.photos/id/237/500/300"}
                    alt="Paella dish"
                    sx={{ objectFit: "contain" }}
                  />

              }

            </aside>



          </form>

          {/* {
            loader
            &&
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              <CircularProgress></CircularProgress>
            </Box>
          } */}

          <div className="d-flex flex-row-reverse">
            <Button form="tour-detail-form" type="submit" disabled={false}>
              {t("global.save_changes")}
            </Button>
          </div>

        </Box>

      </Modal>

      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("tour.are_you_sure_delete")}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>  {t("global.disagree")}</Button>
          <Button onClick={() => deleteAction()} autoFocus>
            {t("global.agree")}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

