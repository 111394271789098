import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useDispatch } from "react-redux";
import { tryLoginRequest } from "../store/auth/actions"
import { Ilogin } from "../store/auth/types";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [user, setUser] = useLocalStorage("userId", null);

    const login = async (data: Ilogin) => {
        const obj = {
            ...data,
            onSuccess: (userId) => {
                setUser(userId);
            },
            onFailure: () => console.log("onFailure auth"),
        }
        dispatch(tryLoginRequest(obj));
    };

    const logout = () => {
        setUser(null);
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
