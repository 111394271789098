import { PropsWithChildren, useState } from "react";
import Box from "@mui/material/Box";
import { DialogActions, Button, Modal, TextField, DialogTitle, Dialog, IconButton, Tooltip, CircularProgress, CardMedia } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ITourItem } from "../../store/tour/types";
import { deleteTourItemRequest, updateTourItemRequest } from "../../store/tour/actions";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone'
import Delete from "@mui/icons-material/Delete";
import ReactAudioPlayer from 'react-audio-player';
import globalStyles from "../../globalStyles";


type Props = {
  item: ITourItem;
  hasMap: boolean;
};

const TourItem: React.FC<PropsWithChildren<Props>> = function TourItem({ item, hasMap }) {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [voiceFile, setVoiceFile] = useState<File>(null);
  const [imageFile, setImageFile] = useState<File>(null);



  // deleteTourItemRequest

  const deleteAction = () => {
    setDeleteLoader(true)
    const meta = {
      onSuccess: () => {
        setOpenDelete(false);
        setDeleteLoader(false)
      },
      onFailure: () => {
        setOpenDelete(false);
        setDeleteLoader(false)
      }
    }
    dispatch(deleteTourItemRequest(item._id, meta));
  }

  const validationSchema = hasMap ? Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'NAme must be at least 3 characters'),
    lat: Yup.number()
      .required('Latitute is required')
      .typeError('Latitute must be a number')
      .min(5, 'Latitute must be at least 5 characters'),
    long: Yup.number()
      .typeError('Amount must be a number')
      .required('Latitute is required')
      .min(5, 'Longitute must be at least 5 characters'),
  }) :
    Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'NAme must be at least 3 characters'),
    })


  const defaultValues = {
    name: item.name,
    ...(hasMap && { lat: item.coordinates.lat ?? "" }),
    ...(hasMap && { long: item.coordinates.long ?? "" }),
  }


  const { control, handleSubmit, formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 30000000,
    accept: {
      'audio/mp3': ['.mp3'],
    },
    onDrop: (acceptedFiles) => {
      setVoiceFile(acceptedFiles[0])
    },
  });

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
    maxSize: 3000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    onDrop: (acceptedFiles) => {
      setImageFile(acceptedFiles[0])
    },
  });

  const voiceFileList = voiceFile ? [voiceFile].map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton onClick={() => setVoiceFile(null)} color="error" aria-label="upload picture" component="label">
        <Delete />
      </IconButton>
    </li>
  )) : null

  const imageFileList = imageFile ? [imageFile].map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton onClick={() => setImageFile(null)} color="error" aria-label="upload picture" component="label">
        <Delete />
      </IconButton>
    </li>
  )) : null



  const onSubmit = data => {

    if (!isValid) {
      return
    }
    setLoader(true);
    const meta = {
      onSuccess: () => {
        setOpen(false);
        setLoader(false);
        setVoiceFile(null);
        setImageFile(null)
      },
      onFailure: () => {
        setOpen(false);
        setLoader(false);
      }
    }

    const formData = new FormData();
    formData.append("_id", item._id);

    if (voiceFile || imageFile) {

      if (voiceFile) {
        formData.append("voice", voiceFile);
      }
      if (imageFile) {
        formData.append("image", imageFile);
      }

      if (hasMap) {
        const obj = {
          name: data.name,
          imageUrl: item.imageUrl,
          voiceUrl: item.voiceUrl,
          _id: item._id
        } as ITourItem
        const coordinates = {
          lat: data.lat,
          long: data.long
        }
        formData.append("coordinates", JSON.stringify(coordinates));
        Object.entries(obj).forEach(([key, val]) => {
          formData.append(key, val as string);
        });
        dispatch(updateTourItemRequest(formData, meta));
      }
      else {
        const obj = {
          name: data.name,
          imageUrl: item.imageUrl,
          voiceUrl: item.voiceUrl,
          _id: item._id
        } as ITourItem
        Object.entries(obj).forEach(([key, val]) => {
          formData.append(key, val as string);
        });
        dispatch(updateTourItemRequest(formData, meta));
      }
    } else {
      if (hasMap) {
        const obj = {
          name: data.name,
          imageUrl: item.imageUrl,
          voiceUrl: item.voiceUrl,
          _id: item._id
        } as ITourItem

        const coordinates = {
          lat: data.lat,
          long: data.long
        }
        formData.append("coordinates", JSON.stringify(coordinates));
        Object.entries(obj).forEach(([key, val]) => {
          formData.append(key, val as string);
        });
        dispatch(updateTourItemRequest(formData, meta));
      }
      else {
        const obj = {
          name: data.name,
          imageUrl: item.imageUrl,
          voiceUrl: item.voiceUrl,
          _id: item._id
        } as ITourItem

        Object.entries(obj).forEach(([key, val]) => {
          formData.append(key, val as string);
        });
        dispatch(updateTourItemRequest(formData, meta));
      }
    }
  }






  return (
    <div className={"container py-2 border-primary border my-2"} key={item._id}>
      <div className="row">
        <div className="col-sm  d-flex justify-content-start align-items-center"> <strong>{item.name}</strong></div>
        {
          item.imageUrl ?
            <div className="col-sm-2">
              <img alt="item describtion" style={{ height: "80px" }} src={"https://api.echopath.app/" + item.imageUrl} />
            </div>
            : <div className="col-sm-2">
            </div>
        }
        <div className="col-sm-7 d-flex justify-content-end align-items-center">
          <ReactAudioPlayer
            style={{ width: "100%" }}
            src={"https://api.echopath.app/" + item.voiceUrl}
            controls
          />
        </div>


        <div className="col-sm d-flex justify-content-end align-items-center">
          <div style={{ display: "flex" }} className="justify-content-end">
            <Tooltip title="EditIcon" placement="left">
              <IconButton onClick={() => setOpen(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title="Delete"
              placement="right"
              onClick={() => setOpenDelete(true)}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={globalStyles.container} >
          <h2 className="text-center">{t("tour.edit_tour_item")}</h2>

          <form id='tour-item-form' onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column overflow-auto pb-3">
            <Controller
              name="name"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["name"]}
                helperText={errors['name'] ? errors['name'].message : ''}
                className="mt-2"
                label={t("global.name")}
                multiline
                maxRows={4}
              />}
            />


            {
              hasMap && (
                <>

                  <Controller
                    name="lat"
                    control={control}
                    render={({ field }) => <TextField    {...field} error={!!errors["lat"]}
                      helperText={errors['lat'] ? errors['lat'].message : ''} className="mt-2" label={t("global.lat")} variant="outlined" />}
                  />

                  <Controller
                    name="long"
                    control={control}
                    render={({ field }) => <TextField    {...field} error={!!errors["long"]}
                      helperText={errors['long'] ? errors['long'].message : ''} className="mt-2" label={t("global.long")} variant="outlined" />}
                  />
                </>
              )

            }

            <div {...getRootProps({ className: 'dropzone mt-2' })} >
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center align-items-center text-center rounded-3" role="button" style={globalStyles.dragDrop}>
                Drag 'n' drop mp3 voice file here, or click to select file
                <br />
                max 30 mb
              </div>
            </div>
            <aside className="mt-2">
              {
                voiceFileList ?

                  <ul>{voiceFileList}</ul>
                  :

                  <ReactAudioPlayer
                    style={{ width: "100%" }}
                    src={"https://api.echopath.app/" + item.voiceUrl}
                    controls
                  />
              }
            </aside>


            <div {...getRootPropsImage({ className: 'dropzone mt-2' })} >
              <input {...getInputPropsImage()} />
              <div className="d-flex justify-content-center align-items-center text-center rounded-3" role="button" style={globalStyles.dragDrop}>
                Drag 'n' drop image file here, or click to select file
                <br />
                max 3 mb
              </div>
            </div>
            <aside className="mt-2">
              {
                imageFileList ?
                  <ul>{imageFileList}</ul>
                  :
                  <CardMedia
                    component="img"
                    height="194"
                    src={"https://api.echopath.app/" + item.imageUrl}
                    image={!item.imageUrl && "https://picsum.photos/id/237/500/300"}
                    alt="Paella dish"
                    sx={{ objectFit: "contain" }}
                  />
              }

            </aside>

          </form>

          {
            loader
            &&
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          }

          <div className="d-flex flex-row-reverse">
            <Button form="tour-item-form" type="submit" disabled={loader}>
              {t("global.save_changes")}
            </Button>
          </div>
        </Box>


      </Modal>



      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("tour.are_you_sure_delete_tour_item")}
        </DialogTitle>
        {
          deleteLoader
          &&
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }

        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>  {t("global.disagree")}</Button>
          <Button onClick={() => deleteAction()} autoFocus>
            {t("global.agree")}
          </Button>
        </DialogActions>

      </Dialog>
    </div>

  );
}

export default TourItem
