import {
  SET_TOURS,
  SET_LOADER_STATE,
  SET_TOUR_DETAIL,
  SET_TOUR_ITEMS
} from "./actionTypes";

import { TourActions,TourState } from "./types";

const initialState: TourState = {
  loader: false,
};

const tourReducer =(state = initialState, action: TourActions) => {
  switch (action.type) {
 
    case SET_TOURS:
      return {
        ...state,
        tourList: action.payload,
      };
    case SET_LOADER_STATE:
        return {
          ...state,
          loader: action.payload,
        };
    case SET_TOUR_DETAIL:
          return {
            ...state,
            tour: action.payload,
          };
    case SET_TOUR_ITEMS:
      return {
        ...state,
        tourItems: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default tourReducer