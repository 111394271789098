import { all, call, put, takeLeading } from "redux-saga/effects";
import { loginFailure, setLoaderState, setPofile } from "./actions";
import { GET_PROFILE_REQUEST, TRY_LOGIN_REQUEST, TRY_SINGUP_REQUEST,UPDATE_PROFILE_REQUEST} from "./actionTypes";
import { authFactory } from "./factory"
import { Ilogin, IProfile, ISingUp } from "./types";


export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}


function* tryLoginSaga(action: { payload: Ilogin, type: string }) {
  const { email, password } = action.payload
  yield put(
    setLoaderState(true)
  );
  try {
    const response: ResponseGenerator = yield call(authFactory.login, { email, password });
    if (response.status === 200) {
      const { token, userId } = response.data
      action.payload?.onSuccess(userId)
      window.localStorage.setItem("token", token);
    } else {
      action.payload?.onFailure()
    }
    yield put(
      setLoaderState(false)
    );

    yield call(getProfileSaga)

  } catch (e: any) {
    action.payload?.onFailure()
    yield put(
      loginFailure({
        error: e.message,
      })
    );
    yield put(
      setLoaderState(false)
    );
  }
}

function* trySingUpSaga(action: { payload: ISingUp, type: string }) {
  const { email, password,name,surname } = action.payload
  yield put(
    setLoaderState(true)
  );
  try {
    const response: ResponseGenerator = yield call(authFactory.singUp, { email, password,name,surname  });
    if (response.status === 201) {
      const { token, userId } = response.data
      action.payload?.onSuccess(userId)
      window.localStorage.setItem("token", token);
      yield call(getProfileSaga)
    } else {
      action.payload?.onFailure()
    }
    yield put(
      setLoaderState(false)
    );

  } catch (e: any) {
    action.payload?.onFailure()
    yield put(
      setLoaderState(false)
    );
  }
}

function* getProfileSaga() {

  try {
    const response: ResponseGenerator = yield call(authFactory.getProfile);
    if (response.status === 200) {
      yield put(setPofile(response.data))
    } else {
     
    }
    yield put(
      setLoaderState(false)
    );

  } catch (e: any) {
    console.log(6666,e);
  }
}



function* updateProfileSaga(action: { payload: IProfile, type: string }) {
  const {
    email,
    surname,
    name,
    identityNumber,
    address,
    iban,
    about,
    socialMedia  } = action.payload
  yield put(
    setLoaderState(true)
  );
  try {
    const response: ResponseGenerator = yield call(authFactory.updateProfile, {
      email,
      name,
      surname,
      identityNumber,
      address,
      iban,
      about,
      socialMedia });
    if (response.status === 200) {
      yield put(setPofile(response.data))
    } else {
      action.payload?.onFailure()
    }
    yield put(
      setLoaderState(false)
    );

    yield call(getProfileSaga)

  } catch (e: any) {
    action.payload?.onFailure()
    yield put(
      loginFailure({
        error: e.message,
      })
    );
    yield put(
      setLoaderState(false)
    );
  }
}


/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* authSaga() {
  yield all([takeLeading(TRY_LOGIN_REQUEST, tryLoginSaga)]);
  yield all([takeLeading(TRY_SINGUP_REQUEST, trySingUpSaga)]);
  yield all([takeLeading(GET_PROFILE_REQUEST, getProfileSaga)]);
  yield all([takeLeading(UPDATE_PROFILE_REQUEST, updateProfileSaga)]);
}

export default authSaga;