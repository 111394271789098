import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../store/rootReducer';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../helper/validations';
import { useTranslation } from 'react-i18next';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {
    const { login, user } = useAuth();
    const navigate = useNavigate()
    const { t } = useTranslation();

    const [email, setEmail] = React.useState('');
    const [pass, setPass] = React.useState('');

    const loader = useSelector((state: AppState) => state.auth.loader);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        login({
            email: data.get('email'),
            password: data.get('password'),
        })
    };


    React.useEffect(() => {
        if (user) {
            navigate("/")
        }

    }, [user,navigate])

    const handleEmailInputChange = event => {
        setEmail(event.target.value);
    };
    const handlePassInputChange = event => {
        setPass(event.target.value);
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("sign_in.sign_in")}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            onChange={handleEmailInputChange}
                            error={!EMAIL_PATTERN.test(email) && email.length > 0}
                            helperText={!EMAIL_PATTERN.test(email) && email.length > 0 && t("sign_in.email_error")}
                            required
                            margin="normal"
                            fullWidth
                            id="email"
                            label={t("sign_in.email")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            onChange={handlePassInputChange}
                            error={!PASSWORD_PATTERN.test(pass) && pass.length > 0}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("sign_in.pass")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {
                            !PASSWORD_PATTERN.test(pass) && pass.length > 0 &&
                            <>
                                <div>
                                    <small className='text-danger'>
                                        * {t("errors.one_upper_case")}
                                    </small>
                                </div>
                                <div>
                                    <small className='text-danger'>
                                        * {t("errors.one_lower_case")}
                                    </small>
                                </div>
                                <div>
                                    <small className='text-danger'>
                                        * {t("errors.one_digit")}
                                    </small>
                                </div>
                                <div>
                                    <small className='text-danger'>
                                        *  {t("errors.at_least", { number: 8 })}
                                    </small>
                                </div>
                            </>
                        }
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={ !PASSWORD_PATTERN.test(pass) || !EMAIL_PATTERN.test(email)}
                        >
                            {t("sign_in.sign_in")}
                        </Button>
                        {
                            loader
                                ?
                                <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                    <CircularProgress></CircularProgress>
                                </Box>
                                :
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2">
                                            {t("sign_in.forgot_pass")}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/SingUp" variant="body2">
                                            {t("sign_in.dont_have_account")}
                                        </Link>
                                    </Grid>
                                </Grid>

                        }

                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}