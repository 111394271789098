import { all, call, put, select, takeLeading } from "redux-saga/effects";
import { DELETE_TOUR_ITEM_REQUEST, DELETE_TOUR_REQUEST, GET_TOURS_REQUEST,GET_TOUR_DETAIL_REQUEST,GET_TOUR_ITEMS_REQUEST,POST_TOUR_ITEM_REQUEST,POST_TOUR_REQUEST, UPDATE_TOUR_DETAIL_REQUEST, UPDATE_TOUR_ITEM_DETAIL_REQUEST, UPDATE_TOUR_ITEM_ORDER_REQUEST} from "./actionTypes";
import { tourFactory } from "./factory"
import { setLoaderState, setTourDetail, setTours, setTourItems } from "./actions";
import { ICallBack, IOrderTourItems, ITour, ITourItem } from "./types";
import { AppState } from "../rootReducer";
import { AxiosResponse } from "axios";



export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}


function* getToursSaga() {
  yield put(
    setLoaderState(true)
  );

  try {
    const response: ResponseGenerator = yield call(tourFactory.getTours);
    const obj = {
      totalItems: response.data.totalItems,
      tours:response.data.tours
    }
    yield put(
      setTours(obj)
    );

    yield put(
      setLoaderState(false)
    );
  

  } catch (e: any) {

    yield put(
      setLoaderState(false)
    );
  }
}

function* setTourSaga(action: { payload: FormData, type: string, meta: ICallBack }) {

  try {
    const response: ResponseGenerator = yield call(tourFactory.setTour, action.payload);

    if (response.status === 201) {
      yield call(getToursSaga)
      action.meta?.onSuccess()
    } else {
      action.meta?.onFailure()
    }
   

  } catch (e: any) {
    console.log(33333,e);
    action.meta?.onFailure()

  }
}

function* deleteTourSaga(action: { payload: string, type: string, meta: ICallBack }) {
  yield put(
    setLoaderState(true)
  );

  try {
    const response: ResponseGenerator = yield call(tourFactory.deleteTour, action.payload);
    
    if (response.status === 200) {
      action.meta?.onSuccess()
    }

    yield put(
      setLoaderState(false)
    );


  } catch (e: any) {
    console.log(333,e);
    yield put(
      setLoaderState(false)
    );
  }
}

function* getTourDetailSaga(action: { payload: string,type:string}) {
  yield put(
    setLoaderState(true)
  );

  try {
    const response: ResponseGenerator = yield call(tourFactory.getTourDetail,action.payload)

    if (response.status === 200) {
      yield put(
        setTourDetail(response.data.tour)
      );
      yield call(getTourItemsSaga,{ type:GET_TOUR_ITEMS_REQUEST, payload:response.data.tour._id })
      
    }

    yield put(
      setLoaderState(false)
    );

  } catch (e: any) {

    yield put(
      setLoaderState(false)
    );
  }
}

function* updateTourSaga(action: { payload: ITour | FormData, type: string, meta: ICallBack }) {
  yield put(
    setLoaderState(true)
  );

  try {
    const response: ResponseGenerator = yield call(tourFactory.updateTour, action.payload );

    if (response.status === 200) {
      action.meta?.onSuccess()
    
      //update detail tour state
      yield put(
        setTourDetail(response.data.tour)
      );

      yield put(
        setLoaderState(false)
      );
    } 
   

  } catch (e: any) {
    yield put(
      setLoaderState(false)
    );
  }
}


function* postTourItemSaga(action: { payload: FormData, type: string, meta: ICallBack }) {

  try {
    const response:  AxiosResponse<{tourItem:ITourItem}>  = yield call(tourFactory.setTourItem, action.payload);

    if (response.status === 201) {
      // yield call(getTourItemsSaga, { type: GET_TOUR_ITEMS_REQUEST, payload: action.payload.tourID })
      const tourItems: ITourItem[] = yield select(
        (state: AppState) => state.tour.tourItems,
      );
      const newTourItem = response.data.tourItem

      yield put(
        setTourItems([...tourItems,newTourItem])
      );
      action.meta?.onSuccess()
    } else {
      action.meta?.onFailure()
    }
   

  } catch (e: any) {
    action.meta?.onFailure()

  }
}

function* getTourItemsSaga(action: { payload: string, type: string }) {

  try {
    const response: ResponseGenerator = yield call(tourFactory.getTourItems,action.payload);
  
    if (response.status === 200 && Boolean(response.data.tourItem)) {
      yield put(
        setTourItems(response.data.tourItem)
      );
    } else {
      yield put(
        setTourItems([])
      );
    }

  } catch (e: any) {

    yield put(
      setTourItems([])
    );
  }
}

function* deleteTourItemSaga(action: { payload: string, type: string, meta: ICallBack }) {

  try {
    const response: ResponseGenerator = yield call(tourFactory.deleteTourItem, action.payload);
    
    if (response.status === 200) {
      const tourItems: ITourItem[] = yield select(
        (state: AppState) => state.tour.tourItems,
      );

      const filteredTourItems=tourItems.filter((el)=>el._id!==action.payload)

      yield put(
        setTourItems([...filteredTourItems])
      );
      action.meta?.onSuccess()
    } else {
      action.meta?.onFailure()
    }

  } catch (e: any) {
    action.meta?.onFailure()
  }
}

function* updateTourItemSaga(action: { payload: ITourItem | FormData, type: string, meta: ICallBack }) {

  try {
    const response: AxiosResponse<{tourItem:ITourItem}> = yield call(tourFactory.updateTourItem, action.payload);

    if (response.status === 200) {

      const tourItems: ITourItem[] = yield select(
        (state: AppState) => state.tour.tourItems,
      );

      const updatedTourItem=response.data.tourItem

      
      const filteredTourItems = tourItems.map((el) => {
        if (el._id === updatedTourItem._id) {
          return updatedTourItem
        } else {
          return el
        }
      })
      yield put(
        setTourItems([...filteredTourItems])
      );
     
      action.meta?.onSuccess()
    } else {
      action.meta?.onFailure()
    }
   

  } catch (e: any) {
    action.meta?.onFailure()

  }
}


function* updateTourItemOrderSaga(action: { payload: IOrderTourItems[], type: string, meta: ICallBack }) {

  try {
    const response: ResponseGenerator = yield call(tourFactory.updateTourItemOrder, action.payload);

    if (response.status === 200) {
     
      action.meta?.onSuccess()
    } else {
      action.meta?.onFailure()
    }
   

  } catch (e: any) {
    action.meta?.onFailure()

  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* authSaga() {
  yield all([takeLeading(GET_TOURS_REQUEST, getToursSaga)]);
  yield all([takeLeading(POST_TOUR_REQUEST, setTourSaga)]);
  yield all([takeLeading(DELETE_TOUR_REQUEST, deleteTourSaga)]);
  yield all([takeLeading(GET_TOUR_DETAIL_REQUEST, getTourDetailSaga)]);
  yield all([takeLeading(UPDATE_TOUR_DETAIL_REQUEST, updateTourSaga)]);
  yield all([takeLeading(POST_TOUR_ITEM_REQUEST, postTourItemSaga)]);
  yield all([takeLeading(GET_TOUR_ITEMS_REQUEST, getTourItemsSaga)]);
  yield all([takeLeading(DELETE_TOUR_ITEM_REQUEST, deleteTourItemSaga)]);
  yield all([takeLeading(UPDATE_TOUR_ITEM_DETAIL_REQUEST, updateTourItemSaga)]);
  yield all([takeLeading(UPDATE_TOUR_ITEM_ORDER_REQUEST, updateTourItemOrderSaga)]);
}

export default authSaga;