import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogTitle, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useLocalStorage } from '../hooks/useLocalStorage';

const Nav_ = function Nav_() {
  const { t, i18n } = useTranslation();
  const { user, logout } = useAuth()
  const [open, setOpen] = React.useState(false);

  const [language, setLanguage] = useLocalStorage("language", "en");

  const data = [
    {
      title: t("global.english"),
      code: "en",
    },
    {
      title: t("global.turkish"),
      code: "tr",
    },
  ];

  const [selectedLang, setSelectedLang] = React.useState(language);

  React.useEffect(() => {
    i18n.changeLanguage(selectedLang);
    setLanguage(selectedLang)
    setOpen(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedLang(event.target.value);
  };


  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">EchoPath</Navbar.Brand>
          <Nav className="ml-auto">
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
          </Nav>
          <Nav className="mr-auto">

            <Nav.Link href="/TourContiner">{t("global.tours")}</Nav.Link>
            <Nav.Link onClick={() => setOpen(true)}>{t("global.language")}</Nav.Link>
            {
              user && <Nav.Link href="/profile">{t("global.profile")}</Nav.Link>
            }
            {
              user ?
                <Nav.Link href="/" onClick={() => logout()}>{t("sign_in.log_out")}</Nav.Link>
                : <Nav.Link href="/singin " onClick={() => logout()}>{t("sign_in.log_in")}</Nav.Link>
            }
          </Nav>
        </Container>
      </Navbar>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >
          {t("global.change_language")}
        </DialogTitle>


        <DialogActions>

          <Select

            labelId="demo-simple-select-label"
            label="language"
            style={{ width: "100%" }}
            onChange={handleChange}
            value={selectedLang}
          >
            <MenuItem value={data[0].code}>{t("global.english")}</MenuItem>
            <MenuItem value={data[1].code}>{t("global.turkish")}</MenuItem>
          </Select>
        </DialogActions>

      </Dialog>

    </>
  );
}

export default Nav_
