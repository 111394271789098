export const GET_TOURS_REQUEST = "GET_TOURS_REQUEST";
export const SET_TOURS = "SET_TOURS";

export const SET_LOADER_STATE = "SET_LOADER_STATE";

export const POST_TOUR_REQUEST = "POST_TOUR_REQUEST";

export const DELETE_TOUR_REQUEST = "DELETE_TOUR_REQUEST";

export const GET_TOUR_DETAIL_REQUEST = "GET_TOUR_DETAIL_REQUEST";
export const SET_TOUR_DETAIL = "SET_TOUR_DETAIL";


export const UPDATE_TOUR_DETAIL_REQUEST = "UPDATE_TOUR_DETAIL_REQUEST";

//tourItems
export const GET_TOUR_ITEMS_REQUEST = "GET_TOUR_ITEMS_REQUEST";
export const SET_TOUR_ITEMS = "SET_TOUR_ITEMS";
export const POST_TOUR_ITEM_REQUEST = "POST_TOUR_ITEM_REQUEST";
export const DELETE_TOUR_ITEM_REQUEST = "DELETE_TOUR_ITEM_REQUEST";
export const UPDATE_TOUR_ITEM_DETAIL_REQUEST = "UPDATE_TOUR_ITEM_DETAIL_REQUEST";

export const UPDATE_TOUR_ITEM_ORDER_REQUEST = "UPDATE_TOUR_ITEM_ORDER_REQUEST";



