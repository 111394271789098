import * as React from 'react';
import Nav from '../components/Nav';


const DashBoard = function DashBoard() {

  return (
    <div>
      <Nav />
    </div>
  );
}

export default DashBoard
