import {
    GET_TOURS_REQUEST,
    SET_TOURS,
    SET_LOADER_STATE,
    POST_TOUR_REQUEST,
    DELETE_TOUR_REQUEST,
    GET_TOUR_DETAIL_REQUEST,
    SET_TOUR_DETAIL,
    UPDATE_TOUR_DETAIL_REQUEST,
    POST_TOUR_ITEM_REQUEST,
    GET_TOUR_ITEMS_REQUEST,
    SET_TOUR_ITEMS,
    DELETE_TOUR_ITEM_REQUEST,
    UPDATE_TOUR_ITEM_DETAIL_REQUEST,
    UPDATE_TOUR_ITEM_ORDER_REQUEST
} from "./actionTypes";
import * as $AT from "./types";
import { IFetchToursSuccessPayload } from "./types";

export const getTourRequest = ( ): $AT.IGetTours => ({
    type: GET_TOURS_REQUEST
});

export const setTours = ( payload: IFetchToursSuccessPayload ): $AT.IFetchToursSuccess => ({
    type: SET_TOURS,
    payload
});

export const setLoaderState = (   payload: boolean): $AT.ISetLoader => ({
    type: SET_LOADER_STATE,
    payload
});

export const postTourRequest = (payload:FormData ,meta?:$AT.ICallBack): $AT.IPostTours => ({
    type: POST_TOUR_REQUEST,
    payload,
    meta
});

export const deleteTourRequest = (payload:string ,meta?:$AT.ICallBack): $AT.IDeleteTours => ({
    type: DELETE_TOUR_REQUEST,
    payload,
    meta
});

export const getTourDetailRequest = (payload:string ): $AT.IGetTourDetailRequest => ({
    type: GET_TOUR_DETAIL_REQUEST,
    payload
});

export const setTourDetail= (payload:$AT.ITour ): $AT.IGetTourDetailRequestSuccess => ({
    type: SET_TOUR_DETAIL,
    payload
});

export const updateTourRequest = (payload:$AT.ITour | FormData ,meta?:$AT.ICallBack): $AT.IPostTours => ({
    type: UPDATE_TOUR_DETAIL_REQUEST,
    payload,
    meta
});

export const postTourItemRequest = (payload:FormData ,meta?:$AT.ICallBack): $AT.IPostTourItem => ({
    type: POST_TOUR_ITEM_REQUEST,
    payload,
    meta
});

export const getTourItems = ( payload:string): $AT.IGetTourItems => ({
    type: GET_TOUR_ITEMS_REQUEST,
    payload,
});

export const setTourItems = ( payload: $AT.ITourItem[] ): $AT.IFetchToursItemsSuccessPayload => ({
    type: SET_TOUR_ITEMS,
    payload
});

export const deleteTourItemRequest = (payload:string ,meta?:$AT.ICallBack): $AT.IDeleteTourItem => ({
    type: DELETE_TOUR_ITEM_REQUEST,
    payload,
    meta
});

export const updateTourItemRequest = (payload:$AT.ITourItem | FormData ,meta?:$AT.ICallBack): $AT.IUpdateTourItem => ({
    type: UPDATE_TOUR_ITEM_DETAIL_REQUEST,
    payload,
    meta
});

export const updateTourItemOrderRequest = (payload:$AT.IOrderTourItems[] ,meta?:$AT.ICallBack): $AT.IUpdateTourItemOrderRequest => ({
    type: UPDATE_TOUR_ITEM_ORDER_REQUEST,
    payload,
    meta
});

