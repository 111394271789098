import * as React from 'react';
import { Provider } from "react-redux";
import Navigation from './Navigation';
import { initializeServices } from './services/initilizer';
import store from "./store";
import { AuthProvider } from "./hooks/useAuth";

import { default as i18n_ } from "i18next";
import { initReactI18next } from "react-i18next";
import languages from "./localization";

export const i18n = i18n_
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: "v3",
    resources: { ...languages },
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });


initializeServices();

export default function App() {


  return (
    <Provider store={store}>
      <AuthProvider>
        <Navigation />
      </AuthProvider>
    </Provider >
  );
}
