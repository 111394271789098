import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/rootReducer";
import { postTourItemRequest, updateTourItemOrderRequest } from "../../store/tour/actions";
import TourItem from "./TourItem";
import { IOrderTourItems, ITourItem } from "../../store/tour/types";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import PlusIcon from "@mui/icons-material/AddCircleOutline";
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone'
import Delete from "@mui/icons-material/Delete";
import globalStyles from "../../globalStyles";



export default function TourItemContainer({ tourID, hasMap }: { tourID: string, hasMap: boolean }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [voiceFile, setVoiceFile] = useState<File>(null);
  const [voiceFileError, setVoiceFileError] = useState(false);
  const [imageFile, setImageFile] = useState<File>(null);

  const [orderList, setOrderList] = useState<IOrderTourItems[]>([]);
  const [disableSaveOrder, setDisableSaveOrder] = useState(true);
  const { tourItems } = useSelector((state: AppState) => state.tour)
  const [itemList, setItemList] = useState<ITourItem[]>(() => tourItems.sort(function (a, b) {
    return a.order - b.order;
  }));

  useEffect(() => {
    const newOrder = tourItems.sort(function (a, b) {
      return a.order - b.order;
    });
    setItemList(newOrder)

  }, [tourItems])

  const validationSchema = hasMap ? Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'NAme must be at least 3 characters'),
    lat: Yup.number()
      .required('Latitute is required')
      .typeError('Latitute must be a number')
      .min(5, 'Latitute must be at least 5 characters'),
    long: Yup.number()
      .typeError('Amount must be a number')
      .required('Latitute is required')
      .min(5, 'Longitute must be at least 5 characters'),
  }) :
    Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .min(3, 'NAme must be at least 3 characters'),
    })


  const defaultValues = {
    name: "",
    ...(hasMap && { lat: "" }),
    ...(hasMap && { long: "" }),
  }

  const { control, handleSubmit, reset, formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {

    if (voiceFile) {
      setLoader(true);

      if (!isValid) {
        return
      }

      const formData = new FormData();
      formData.append("voice", voiceFile);
      if (imageFile) {
        formData.append("image", imageFile);
      }

      const meta = {
        onSuccess: () => {
          setOpen(false);
          setLoader(false);
          setVoiceFile(null);
          setImageFile(null)
          reset(defaultValues)
        },
        onFailure: () => {
          setOpen(false);
          setLoader(false);
        }
      }

      if (hasMap) {
        const obj = {
          name: data.name,
          tourID
        } as ITourItem

        const coordinates = {
          lat: data.lat,
          long: data.long
        }

        formData.append("coordinates", JSON.stringify(coordinates));
        Object.entries(obj).forEach(([key, val]) => {
          formData.append(key, val);
        });
        dispatch(postTourItemRequest(formData, meta));
      } else {
        Object.entries(data).forEach(([key, val]) => {
          formData.append(key, val as string);
        });
        formData.append("tourID", tourID);
        dispatch(postTourItemRequest(formData, meta));
      }
    } else {
      setVoiceFileError(true)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 30000000,
    accept: {
      'audio/mp3': ['.mp3'],
    },
    onDrop: (acceptedFiles) => {
      setVoiceFile(acceptedFiles[0])
      setVoiceFileError(false)
    },
  });

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
    maxSize: 3000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    onDrop: (acceptedFiles) => {
      setImageFile(acceptedFiles[0])
    },
  });

  const voiceFileList = voiceFile ? [voiceFile].map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton onClick={() => setVoiceFile(null)} color="error" aria-label="upload picture" component="label">
        <Delete />
      </IconButton>
    </li>
  )) : null

  const imageFileList = imageFile ? [imageFile].map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton onClick={() => setImageFile(null)} color="error" aria-label="upload picture" component="label">
        <Delete />
      </IconButton>
    </li>
  )) : null

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);

    const orderedList = updatedList.map((el, i) => {
      return { order: i, _id: el._id }
    })

    setOrderList(orderedList)
    setDisableSaveOrder(false)
  };

  const saveOrder = useCallback(() => {
    setLoader(true);
    const meta = {
      onSuccess: () => {
        setLoader(false);
        setDisableSaveOrder(true)
      },
      onFailure: () => {
        setLoader(false);
      }
    }

    dispatch(updateTourItemOrderRequest(orderList, meta));
  }, [dispatch, orderList])

  return (
    <div className="mt-5 px-3 ">
      <div className="d-flex justify-content-end px-2">
        <div >
          <Button startIcon={<PlusIcon />} onClick={() => setOpen(true)} >
            {t("tour.add_new_tour_item")}
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemList.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided) => (
                    <div
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <TourItem item={item} hasMap={hasMap} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="d-flex justify-content-end px-2">
        <div >
          <Button startIcon={<SaveIcon />} disabled={disableSaveOrder} onClick={() => saveOrder()} >
            {t("tour.save_list_order")}
          </Button>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={globalStyles.container} >
          <h2 className="text-center"> {t("tour.add_new_tour_item")}</h2>

          <form id="create-tour-item" onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column overflow-auto pb-3">
            <Controller
              name="name"
              control={control}
              render={({ field }) => <TextField
                {...field}
                error={!!errors["name"]}
                helperText={errors['name'] ? errors['name'].message : ''}
                className="mt-2"
                label={t("global.name")}
                multiline
                maxRows={4}
              />}
            />


            {
              hasMap && (
                <>

                  <Controller
                    name="lat"
                    control={control}
                    render={({ field }) => <TextField    {...field} error={!!errors["lat"]}
                      helperText={errors['lat'] ? errors['lat'].message : ''} className="mt-2" label={t("global.lat")} variant="outlined" />}
                  />

                  <Controller
                    name="long"
                    control={control}
                    render={({ field }) => <TextField    {...field} error={!!errors["long"]}
                      helperText={errors['long'] ? errors['long'].message : ''} className="mt-2" label={t("global.long")} variant="outlined" />}
                  />
                </>
              )

            }

            <div {...getRootProps({ className: 'dropzone mt-2' })} >
              <input {...getInputProps()} />
              <div className="d-flex justify-content-center align-items-center text-center rounded-3" role="button" style={voiceFileError ? globalStyles.dragDropError : globalStyles.dragDrop}>
                Drag 'n' drop mp3 voice file here, or click to select file
                <br />
                max 30 mb
              </div>
            </div>
            <aside className="mt-2">
              {
                voiceFileError ?
                  <div className="text-danger"> Must add voice file</div>
                  :
                  <ul>{voiceFileList}</ul>
              }

            </aside>


            <div {...getRootPropsImage({ className: 'dropzone mt-2' })} >
              <input {...getInputPropsImage()} />
              <div className="d-flex justify-content-center align-items-center text-center rounded-3" role="button" style={globalStyles.dragDrop}>
                Drag 'n' drop image file here, or click to select file
                <br />
                max 3 mb
              </div>
            </div>
            <aside className="mt-2">
              {

                <ul>{imageFileList}</ul>
              }

            </aside>





          </form>
          <div className="d-flex flex-row-reverse">
            <Button form="create-tour-item" type="submit" disabled={loader}>
              {t("global.save_changes")}
            </Button>
          </div>

          {
            loader
            &&
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          }

        </Box>

      </Modal>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
        onClick={() => setLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div >
  );
}