export const TRY_LOGIN_REQUEST = "TRY_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SET_LOADER_STATE = "SET_LOADER_STATE";
export const TRY_SINGUP_REQUEST = "TRY_SINGUP_REQUEST";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const SET_PROFILE = "SET_PROFILE";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";

