import { httpService } from "../../services/httpService";
import { Ilogin,IProfile,ISingUp } from "./types";

class AuthFactory {

    login(data: Ilogin) {
        return httpService.post("guide/loginguide", data)
    }

    singUp(data: ISingUp) {
        return httpService.post("guide/signupguide", data)
    }

    getProfile() {
        return httpService.get("guide/profile")
    }

    updateProfile(data: IProfile) {
        return httpService.post("guide/updateProfile", data)
    }
}

export const authFactory = new AuthFactory();
