import {
  SET_LOADER_STATE, SET_PROFILE
} from "./actionTypes";

import { AuthActions,AuthState } from "./types";

const initialState: AuthState = {
  loader: false,
};

const authReducer= (state = initialState, action: AuthActions) => {
  switch (action.type) {
 
    case SET_LOADER_STATE:
      return {
        ...state,
        loader: action.payload,
    
      };
    case SET_PROFILE:
        return {
          ...state,
          user: action.payload,
        };
    default:
      return {
        ...state,
      };
  }
};
export default authReducer