import { useEffect } from "react";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { AppState } from "../store/rootReducer";
import Nav from "../components/Nav";
import { getTourDetailRequest, } from "../store/tour/actions";
import TourDetailHeader from "./components/TourDetailHeader";
import TourItemContainer from "./components/TourItemContainer";
import { useTranslation } from "react-i18next";



export default function TourDetail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const tourID = searchParams.get('id')

  const { loader, tour } = useSelector((state: AppState) => state.tour)

  useEffect(() => {
    dispatch(getTourDetailRequest(tourID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Nav />

      {loader
        ?
        <Box sx={{ display: 'flex', justifyContent: "center" }}>
          <CircularProgress></CircularProgress>
        </Box>
        :
        tour
        &&
        <div className="px-3 pb-5">

          <TourDetailHeader tourId={tourID} />

          <h1 className="text-center py-3 text-secondary">{tour.name}</h1>
          <CardMedia
            component="img"
            height="194"
            src={"https://api.echopath.app/" + tour.imageUrl}
            image={!tour.imageUrl && "https://picsum.photos/id/237/500/300"}
            alt="Paella dish"
            sx={{ objectFit: "contain" }}
          />

          <div className="text-left pt-5 px-3">
            {tour.description}
          </div>

          <div className="d-flex justify-content-start mt-4">
            <div className="px-3">
              <span style={{ fontWeight: 'bold' }}>{t("tour.country")}: </span>  {tour.country}
              <div className="mt-1" >
                <span style={{ fontWeight: 'bold' }}>{t("tour.city")}: </span>  {tour.city}
              </div>
              <span style={{ fontWeight: 'bold' }}>{t("tour.currency")}: </span>  {tour.currency}
              <div className="mt-1" >
                <span style={{ fontWeight: 'bold' }}>{t("tour.price")}: </span>  {tour.price}
              </div>
            </div>
          </div>
          <TourItemContainer tourID={tourID} hasMap={tour.hasMap} />

        </div >
      }


    </div>

  );
}

